
import { defineComponent, ref, computed } from 'vue';

import useAuthentication from '@/uses/useAuthentication';
import useView from '@/uses/useView';

import getConfig from '@/plugins/config';

const GRID_URL = getConfig('VUE_APP_GRIDS_BASE_URL');

export default defineComponent({
  setup() {
    const { username } = useAuthentication();

    const { views } = useView();

    const isSidebarVisible = ref<boolean>(false);

    const isMenuVisible = ref<boolean>(true);

    const toggleSidebar = (): void => {
      isSidebarVisible.value = !isSidebarVisible.value;
    };

    const toggleMenu = (): void => {
      isMenuVisible.value = !isMenuVisible.value;
    };

    const hasUrlValue = computed(() => GRID_URL !== '');

    return {
      username,
      views,
      isSidebarVisible,
      isMenuVisible,
      hasUrlValue,
      toggleSidebar,
      toggleMenu,
    };
  },
});

