import { computed, ComputedRef } from 'vue';

import { useStore } from 'vuex';
import { IFiscalCalendarRow } from '@/interfaces/FiscalCalendar';

/**
 * Dataset Composition API module
 */
export default function useFiscalCalendar(): {
  fiscalCalendar: ComputedRef<IFiscalCalendarRow[]>;
  fetchFiscalCalendar: (customer: string) => Promise<void>;
  } {
  const store = useStore();

  const fiscalCalendar = computed(():IFiscalCalendarRow[] => store.getters['FiscalCalendar/getFiscalCalendar']);

  /**
   *
   *
   * @return {*}  {Promise<void>}
   */
  async function fetchFiscalCalendar(customer: string): Promise<void> {
    try {
      await store.dispatch('FiscalCalendar/fetchFiscalCalendar', customer);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    fiscalCalendar,
    fetchFiscalCalendar,
  };
}
